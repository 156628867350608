import { defineNuxtRouteMiddleware } from 'nuxt/app'
import { RecentParties } from '../lib/interfaces/RecentParty'

export default defineNuxtRouteMiddleware(async (to) => {
  const partiesDb = new RecentParties()

  const uuid: string = to.params.uuid as string
  const party = await partiesDb.getPartyByUuid(uuid)

  if (party) {
    await partiesDb.updateLastVisited(party)
  }
})
